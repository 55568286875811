import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Arrow_left_icon from '../../Assets/Icons/Arrow_left_icon.svg';
import Arrow_right_icon from '../../Assets/Icons/Arrow_right__icon.svg';
import zoom0 from '../../Assets/Icons/zoom0b.png';
import zoom1 from '../../Assets/Icons/zoom1b.png';
import zoom2 from '../../Assets/Icons/zoom2b.png';
import share_icon from '../../Assets/Icons/Share_icon_bule.svg';
import JsonData from '../../dataMain.json';
import Form from "react-bootstrap/Form";
import { useLocation, useParams } from 'react-router-dom';
import useWindowDimensions from '../../Components/windowdimensions';
import { GLOBAL_DOMAIN, GLOBAL_VERSION, GLOBAL_IMAGES, GLOBAL_CONTAINER } from '../../Config/Config';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import whatsapp_icon from '../../Assets/Icons/whatsapp.png';
import copy_icon from '../../Assets/Icons/copy.png';
import Placeholder from 'react-bootstrap/Placeholder';

const Paperviewer = (props) => {

    const elementRef = useRef(null);
    const location = useLocation();
    const params = useParams();
    const mulitiplepaperdata = location?.state?.PaperData;
    const [arrowDisable, setArrowDisable] = useState(true);
    const NewspapperData = JsonData.NewsPapper;
    const [date, setdate] = useState();
    const [selectedData, setselectedData] = useState();
    const [selectedNewspapperData, setselectedNewspapperData] = useState();
    const [pageindex, setpageindex] = useState(0);
    const [imgStyle, setimgStyle] = useState({ height: '', width: '' });
    const { height, ScreenWidth } = useWindowDimensions();
    const [getnews, setnews] = useState();
    const [show, setShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const textAreaRef = useRef(null);
    const [errorflag, seterrorflag] = useState(false);

    useEffect(() => {
        // setdate(new Date);
        // filterdata();
        if (ScreenWidth <= 386) {
            setimgStyle({ height: 100, width: 100 })
        } else {
            setimgStyle({ height: 300, width: 100 })
        }
        console.log(ScreenWidth);
        console.log(params.id);
        if (params.id) {
            GETNews();
        }
    }, [ScreenWidth]);

    //filter dater into yyyy-mm-dd
    const filterdata = useCallback(() => {
        const filterpaper = NewspapperData?.filter((item) => item?.ID === mulitiplepaperdata?.ID)
        setselectedNewspapperData(filterpaper);
        setselectedData(filterpaper[0]?.imageData[0]);
        setpageindex(0)
    }, [])

    // Carousel indicators action next/prev 
    const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
            if (element.scrollLeft === 0) {
                setArrowDisable(true);
            } else {
                setArrowDisable(false);
            }
        }, speed);
    };

    const getselecteddata = (event, index) => {
        setselectedData(event.image_path);
        setpageindex(index);
    }

    // page shift next/prev newspaper
    const nextpage = (nextevent) => {
        if ((selectedNewspapperData?.length) - 1 > nextevent) {
            console.log("next working")
            setselectedData(selectedNewspapperData[nextevent + 1].image_path);
            setpageindex(nextevent + 1);
        }
    }
    const prevpage = (nextevent) => {
        console.log(nextevent, selectedNewspapperData?.length - 1)
        if (((selectedNewspapperData?.length) - 1 >= nextevent) && pageindex != 0) {
            console.log("prev working")
            setselectedData(selectedNewspapperData[nextevent - 1].image_path);
            setpageindex(nextevent - 1);
            console.log(pageindex)
        }
    }

    //zooming action for the news paper(Div)
    const actionZoom = (eventzoom) => {
        if (ScreenWidth <= 786) {
            if (eventzoom === 0) {
                setimgStyle({
                    height: 100,
                    width: 100
                })
            } else if (eventzoom === 1) {
                setimgStyle({
                    height: 300,
                    width: 227
                })
            } else {
                setimgStyle({
                    height: 320,
                    width: 466
                })
            }
        } else {
            if (eventzoom === 0) {
                setimgStyle({
                    height: 300,
                    width: 100
                }
                )
            } else if (eventzoom === 1) {
                setimgStyle({
                    height: 520,
                    width: 180
                }
                )
            } else {
                setimgStyle({
                    height: 620,
                    width: 230
                }
                )
            }
        }
    }

    //set selected date news paper
    const dateaction = (event) => {
        setdate(event.target.value)
        console.log(event.target.value)
    }

    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const GETNews = () => {
        axios({
            method: 'get',
            // headers: Api_Headers,
            url: GLOBAL_DOMAIN + GLOBAL_VERSION + 'news/' + params.id,
        }).then((res) => {
            console.log(res.data);
            setnews(res?.data);
            setselectedNewspapperData(res?.data?.images)
            getselecteddata(res.data?.images[0], 0)
            setdate(formatDate(res.data.date))
            // setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
            seterrorflag(true);
        });
    }
    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        // // textAreaRef.current.select();
        // document.execCommand('copy');
        // // This is just personal preference.
        // // I prefer to not show the whole text area selected.
        // // e.target.focus();
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 5000);
    };
    const sendtowhatsapp = (event) => {
        console.log(event)
        const whatsapp_url = 'https://api.whatsapp.com/send?text=' + event;
        window.open(whatsapp_url);
    }
    return (
        <section>
            {!errorflag ?
                <React.Fragment>
                    {selectedNewspapperData && selectedNewspapperData?.length > 0 ?
                        <div>
                            <div className='position-fixed w-100 mt-80 z-index-999'>
                                <div className='border bg-white px-5'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <Form.Control type="date" disabled value={date} onChange={(e) => dateaction(e)} />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {ScreenWidth >= 486 &&
                                                <div className='d-flex justify-content-between mx-4'>
                                                    <div className='cursor_pointer'>
                                                        <p className='m-0' onClick={() => { prevpage(pageindex) }}>Prev</p>
                                                    </div>
                                                    <div className='mx-2'><p className='border px-2 m-0'>{pageindex + 1}</p></div>
                                                    <div className='cursor_pointer'>
                                                        <p className='m-0' onClick={() => { nextpage(pageindex) }}>Next</p>
                                                    </div>
                                                </div>
                                            }
                                            <div className='d-flex'>
                                                <div>
                                                    <img className='cursor_pointer' alt='zoom0' src={zoom0} onClick={() => { actionZoom(0) }} />
                                                </div>
                                                <div>
                                                    <img className='cursor_pointer' alt='zoom1' src={zoom1} onClick={() => { actionZoom(1) }} />
                                                </div>
                                                <div>
                                                    <img className='cursor_pointer' alt='zoom2' src={zoom2} onClick={() => { actionZoom(2) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-120'>
                                <div className='container'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p><span className='me-2 fw-bold'>Date:</span>{getnews.date} / {getnews.state_name}</p>
                                        </div>
                                        <div>
                                            <img className='cursor_pointer' src={share_icon} alt='share icon' onClick={() => setShow(true)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-color img_carousel">
                                    <div className="button-contianer-left">
                                        <img
                                            className='cursor_pointer'
                                            alt='icon left'
                                            onClick={() => {
                                                handleHorizantalScroll(elementRef.current, 25, 200, -10);
                                            }}
                                            src={Arrow_left_icon}
                                            disabled={arrowDisable}
                                        />
                                    </div>
                                    <div className="button-contianer-right">
                                        <img
                                            className='cursor_pointer'
                                            alt='icon right'
                                            onClick={() => {
                                                handleHorizantalScroll(elementRef.current, 25, 200, 10);
                                            }}
                                            src={Arrow_right_icon}
                                        />
                                    </div>
                                    <div className='container py-3 px-4'>
                                        <Row className='scrollbar_hidden img-container' ref={elementRef}>
                                            <React.Fragment>
                                                {selectedNewspapperData && selectedNewspapperData?.map((data, index) => {
                                                    return (
                                                        <Col md={3} lg={2} sm={4} xs={6} key={index} className={pageindex == index ? "selected_border" : ''}>
                                                            {pageindex == index &&
                                                                <div class="pageNum"><span>{pageindex + 1}</span></div>
                                                            }
                                                            <div className="product-item cursor_pointer" onClick={() => { getselecteddata(data, index) }}>
                                                                <div className="product-item-content text-center ">
                                                                    <div className="">
                                                                        <img src={GLOBAL_IMAGES + GLOBAL_CONTAINER + '/' + data.image_path} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt='news paper' height='200px' width='140px' className="product-image category_item" style={{}} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })}
                                            </React.Fragment>
                                        </Row>
                                    </div>
                                </div>
                                <div className='container'>
                                    {/* <div className=''> */}
                                    <div className='paper_img paper_data_viewer' >
                                        <img src={GLOBAL_IMAGES + GLOBAL_CONTAINER + '/' + selectedData} style={{ height: 'auto', width: imgStyle.width + '%' }} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} ></img>
                                        {/* <img src={selectedData} style={{height: imgStyle.height + 'vh', width: imgStyle.width + '%' }}></img> */}
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        :
                        <div className='container' style={{ paddingTop: '100px' }}>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={4} style={{ height: '5vh' }} />
                            </Placeholder>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={12} style={{ height: '20vh' }} />
                            </Placeholder>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={12} style={{ height: '40vh' }} />
                            </Placeholder>
                        </div>
                    }
                </React.Fragment>
                :
                <div className='pt-120 container'>
                    <h2>Something went Worng......</h2>
                    <p>Please try again later</p>
                </div>
            }
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Share Link
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center my-2'>
                        <div className='me-2 d-flex align-items-center border p-2 cursor_pointer' onClick={() => sendtowhatsapp(window.location.href)}>
                            <div
                                className="share-icon"><img src={whatsapp_icon} width={30} /></div>
                            <p className='m-0 px-2'>whatsapp</p>
                        </div>
                        <div className='postion-relative' onClick={() => copyToClipboard(window.location.href)} >
                            <div className='me-2 d-flex align-items-center border p-2 cursor_pointer'>
                                <img src={copy_icon} width={30} />
                                <p className='m-0 px-2'>Copy</p>
                            </div>
                            {copySuccess &&
                                <div className='position-absolute'>
                                    <p className='m-0 text-bule'>Link Copied</p>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Paperviewer