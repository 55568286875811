import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Data from './test-data.json';

import './App.css';

export default function Info (){
    const { id } = useParams();

    const [result, setResult] = useState('');

    useEffect(() => {
        if(id){
            let results = [];

            for(let i=0; i<Data.articles.length; i++){
                if(Data.articles[i].title===id){
                    results.push(Data.articles[i]);
                };
            };

            for(let i=0; i<Data.sidebar.length; i++){
                if(Data.sidebar[i].title===id){
                    results.push(Data.sidebar[i]);
                };
            };
    
            if(results){
                setResult(results);
            };
        };
    }, []);

    return (
        result!=='' && result.map((it, idx) => <div key={idx}>
            <h2 style={{textAlign: 'center'}}>{it.title}</h2>

            <p style={{textAlign: 'right', marginRight: '20vw'}}>{it.author}</p>

            <div className='infopics'>
                {it.urlToImage && <img src={it.urlToImage} />}
            </div>
            
            <div style={{fontSize: '16px', textAlign: 'left', paddingLeft: '2vw'}}>
                {it.content}
                <br style={{marginBottom: '7.5px'}} />
                {it.description}
            </div>
        </div>)
    );
};